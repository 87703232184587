import React, { useState } from "react";
// import for nav
import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
// imports
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { Divider, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
// immports for animation
import AOS from "aos";
import "aos/dist/aos.css";
//import css
import "../../css/chooseus/ChooseUs.css";
//image imports
import aboutImage from "../../assets/aboutImage.png";
import mission from "../../assets/mission.jpg";
import vision from "../../assets/vision.jpg";
import achievement from "../../assets/s1-rv.png";
//icon imports
import CoPresentSharpIcon from "@mui/icons-material/CoPresentSharp";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import BoltIcon from "@mui/icons-material/Bolt";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";

//component imports

//import hooks
import IndexaPageHook from "../../hooks/indexpageHook/IndexaPageHook";

//child imports
import Services from "./Services";
import OtherServices from "./OtherServices";
import Achievements from "./Achievements";

//Media Query
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

// Functions for tab in mission vission
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const IndexPage = () => {
  // for animation
  AOS.init();
  //hook
  const { ...param } = IndexaPageHook();
  //media query
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // for navigation bar
  const selectedLink = useSelector((state) => state.IndexReducer.selectedLink);
  const homeRef = useRef();
  const aboutRef = useRef();
  const servicesRef = useRef();
  // const contactRef = useRef();
  useEffect(() => {
    if (selectedLink === "HOME") {
      homeRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (selectedLink === "ABOUT US") {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (selectedLink === "SERVICES") {
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // if (selectedLink === "CONTACT US") {
    //   contactRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  }, [selectedLink]);

  //for count up
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //contact
  const CssTextField = styled(TextField)({
    "& .MuiFilledInput-root": {
      backgroundColor: "#EE9D1C !important",
    },
    "& .Mui-focused": {
      backgroundColor: "#ff5e14 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000 !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000 ",
    },
    ".MuiFilledInput-multiline": {
      backgroundColor: "ff5e14 !important",
    },
    "& .onhover": {
      backgroundColor: "#ff5e14 !important",
    },
    "& label.Mui-focused": {
      color: "#fff",
    },
  });

  return (
    <>
      {/* <Grid container spacing={0}> */}
      {/*=============== Header================ */}
      <div
        ref={homeRef}
        className="indexbanner"
        style={{ paddingTop: "105px" }}
      >
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <div>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <div className={isMobile ? "mobileHeroText" : "heroText"}>
                    <Stack gap={0}>
                      <Typography
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="300"
                        className={isMobile ? "mobileHeaderText" : "HeaderText"}
                      >
                        Let's start something
                      </Typography>
                      <Typography
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="500"
                        className={
                          isMobile ? "mobileHeaderText2" : "HeaderText2"
                        }
                      >
                        BIG TOGETHER.
                      </Typography>
                    </Stack>
                    <Typography
                      data-aos="fade-up"
                      data-aos-duration="800"
                      data-aos-delay="700"
                      className={isMobile ? "mobileHeaderText3" : "HeaderText3"}
                    >
                      Start your collaboration with MMSC today! Take a look at
                      the services we offer below, and then get in touch with us
                      now.
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        alignContent: "center",
                        justifyContent: "center",
                        paddingTop: "30px",
                      }}
                    >
                      <Button
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="700"
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.replace("/#contact");
                        }}
                        className="hBTN"
                        variant="outlined"
                      >
                        Contact Us
                      </Button>
                      <Button
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="800"
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.replace("/#about");
                        }}
                        className="hBTN2"
                        variant="contained"
                      >
                        What We Do?
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* =================MMSC Services=============== */}
      <div className={isMobile ? "mobileMMSCServ" : "MMSCServ"}>
        <div>
          <div>
            <div>
              <Grid container spacing={3}>
                {param.indexSolutionData.map((val, index) => {
                  const multiplier = index * 100;
                  return (
                    <>
                      <OtherServices
                        delay={multiplier}
                        image={val.image}
                        text={val.text}
                        // selectedService={param.selectedService}
                        // selectedServ={param.selectedServ}
                      />
                    </>
                  );
                })}
              </Grid>
              <div
                data-aos="zoom-in"
                data-aos-duration="800"
                data-aos-delay="50"
                style={{ alignItems: "center", textAlign: "center" }}
              >
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.replace("/#services");
                  }}
                  className="hBTN2"
                >
                  VIEW ALL SERVICES
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================About Us Section============ */}
      <div
        ref={aboutRef}
        id="about"
        className={isMobile ? "mobileAbout" : "About"}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <div>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <div
                    data-aos="fade-down"
                    data-aos-duration="800"
                    data-aos-delay="50"
                    style={{ width: "100%", alignItems: "center" }}
                  >
                    <img src={aboutImage} style={{ width: "90%" }} />
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div style={{ padding: "10px 20px 10px 20px" }}>
                    <Stack direction="row" gap={2} marginBottom="20px">
                      <CoPresentSharpIcon
                        data-aos="fade-down"
                        data-aos-duration="800"
                        className="iconHead"
                      />
                      <Typography
                        data-aos="fade-down"
                        data-aos-duration="800"
                        data-aos-delay="100"
                        className="titleHead"
                      >
                        ABOUT US
                      </Typography>
                    </Stack>

                    <Typography
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-delay="100"
                      className="titleText"
                    >
                      We Are A Leader <br /> In The Utility Sector
                    </Typography>
                    <Typography
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-delay="150"
                      className="titleText2"
                    >
                      MMSC is a prominent service provider in the field of water
                      and electric meter reading, prioritizing precision and
                      effectiveness. MMSC is dedicated to delivering top-notch
                      services and upholding a firm dedication to their clients,
                      workforce, and the environment.
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item md={6} xs={6}>
                        <div style={{ padding: "30px 20px 10px 20px" }}>
                          <Stack direction="row" gap={1} marginBottom="20px">
                            <WorkspacePremiumIcon
                              data-aos="fade-down"
                              data-aos-duration="800"
                              data-aos-delay="150"
                              className="iconCard"
                            />
                            <Typography
                              data-aos="fade-down"
                              data-aos-duration="800"
                              data-aos-delay="150"
                              className={
                                isMobile ? "mobileAboutText" : "AboutText"
                              }
                            >
                              Most Trusted Field Outsourcing Service Provider.
                            </Typography>
                          </Stack>
                        </div>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <div style={{ padding: "30px 20px 10px 20px" }}>
                          <Stack direction="row" gap={2} marginBottom="20px">
                            <PeopleAltIcon className="iconCard" />
                            <Typography
                              data-aos="fade-down"
                              data-aos-duration="800"
                              data-aos-delay="175"
                              className={
                                isMobile ? "mobileAboutText" : "AboutText"
                              }
                            >
                              Highly Expart and Qualified Staff
                            </Typography>
                          </Stack>
                        </div>
                      </Grid>
                    </Grid>
                    <div
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-delay="100"
                      className={isMobile ? "mobileFeedback" : "Feedback"}
                    >
                      <Grid container spacing={1}>
                        <Stack direction="row" gap={1}>
                          <Typography
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              alignItems: "center",
                              fontSize: "20px",
                              fontStyle: "italic",
                            }}
                          >
                            Dramatically facilitate industry's utility needs
                            whereas with communities.
                          </Typography>
                          <FormatQuoteRoundedIcon
                            className={isMobile ? "mobilequote" : "quote"}
                          />
                        </Stack>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* ================Our Services================= */}
      <div ref={servicesRef} id="services" className="solutionbanner">
        <div className={isMobile ? "mobileServ" : "Serv"}>
          <Stack
            direction="row"
            gap={2}
            margin="20px 0px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CoPresentSharpIcon
              className={isMobile ? "mobileaiconHead" : "iconHead"}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                className={isMobile ? "mobiletitleHead" : "titleHead"}
              >
                OUR SERVICES
              </Typography>
            </div>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="100"
              className={isMobile ? "mobileServText" : "ServText"}
            >
              The Best Service For You
            </Typography>
          </div>
          <div>
            <Grid container spacing={3}>
              {param.indexCardData.map((val, index) => {
                const multiplier = index * 100;
                return (
                  <>
                    <Services
                      delay={multiplier}
                      icon={val.icon}
                      title={val.title}
                      text={val.text}
                    />
                  </>
                );
              })}
            </Grid>
          </div>
        </div>
      </div>
      {/* ===================Get in Touch=============*/}
      <div className="callusbanner">
        <div className={isMobile ? "mobileGIT" : "GIT"}>
          <Grid container spacing={1}>
            <Grid item md={8} xs={12}>
              <Typography className={isMobile ? "mobileGITtxt" : "GITtxt"}>
                Have Any Question In Your Mind?
              </Typography>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={2} xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  className={isMobile ? "mobileGITBtn" : "GITBtn"}
                  elevation={2}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.replace("/#contact");
                  }}
                  // sx={{
                  //   padding: "20px",
                  //   color: "#f94a29",
                  //   backgroundColor: "white",
                  //   cursor: "pointer",
                  //   "&:hover": {
                  //     color: "white",
                  //     backgroundColor: "black",
                  //   },
                  // }}
                >
                  Get in Touch →
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      {/*=======why choose us===================  */}
      <div>
        <div className={isMobile ? "mobileWhy" : "Why"}>
          <Stack
            direction="row"
            gap={2}
            marginBottom="20px"
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <CoPresentSharpIcon
              sx={{
                color: "#E04D01",
                fontSize: "32px",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  textAlign: "left",
                  color: "#E04D01",
                  // fontFamily: "Exo",
                }}
              >
                WHY CHOOSE US
              </Typography>
            </div>
          </Stack>
          <Typography
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="100"
            className={isMobile ? "mobileWhyHead" : "WhyHead"}
          >
            We Help You Build On Your <br /> Past And Prepare For The Future
          </Typography>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              textColor="orange"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#f94a29",
                },
              }}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
            >
              <Tab label="Mission" {...a11yProps(0)} />
              <Tab label="Vision" {...a11yProps(1)} />
              {/* <Tab label="Value" {...a11yProps(2)} />
                <Tab label="Award Winning" {...a11yProps(3)} /> */}
            </Tabs>
          </Box>
          <TabPanel
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="200"
            value={value}
            index={0}
          >
            <div className={isMobile ? "mobileWhyMission" : "WhyHeadMission"}>
              <Grid container spacing={1}>
                {/* <Grid item xs={3}></Grid> */}
                <Grid item md={6} xs={12}>
                  <img
                    src={mission}
                    className={isMobile ? "mobileImgmv" : "Img"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Stack direction="column" gap={1}>
                    <Typography className={isMobile ? "mobiletxt4" : "txt4"}>
                      Leading Way In Reading & Transportation Services!
                    </Typography>
                    <Typography className={isMobile ? "mobiletxt2" : "txt2"}>
                      Our mission at MMSC is to provide accurate and reliable
                      water and electric reading services to our customers. We
                      are committed to delivering excellent customer service,
                      using the latest technology and equipment, and
                      continuously improving our processes to ensure maximum
                      efficiency and satisfaction for all our clients.
                    </Typography>
                    <div style={{ paddingTop: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item md={6} xs={6}>
                          {param.indexTabsData1.map((tabs) => {
                            return (
                              <Stack direction="row" gap={1}>
                                <Typography>{tabs.icon}</Typography>
                                <Typography>{tabs.text}</Typography>
                              </Stack>
                            );
                          })}
                        </Grid>
                        <Grid item md={6} xs={6}>
                          {param.indexTabsData2.map((tabs2) => {
                            return (
                              <Stack direction="row" gap={1}>
                                <Typography>{tabs2.icon}</Typography>
                                <Typography>{tabs2.text}</Typography>
                              </Stack>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </div>
                    <Divider
                      sx={{ height: "2px", backgroundColor: "#EEEEEE" }}
                    />
                    <Grid container spacing={0}>
                      <Grid item md={6} xs={12}>
                        <Grid container spacing={1}>
                          <Grid item md={3}>
                            <div
                              style={{
                                paddingTop: "20px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <BoltIcon
                                sx={{
                                  color: "#E04D01",
                                  fontSize: "50px",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  paddingLeft: "20px",
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={9}>
                            <div style={{ paddingTop: "20px" }}>
                              <Stack direction="column" gap={0}>
                                <Typography
                                  sx={{ fontSize: "10px", color: "#E04D01" }}
                                >
                                  READING
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Reading Services
                                </Typography>
                              </Stack>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Grid container spacing={1}>
                          <Grid item md={3}>
                            <div
                              style={{
                                paddingTop: "20px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <DirectionsBusFilledIcon
                                sx={{
                                  color: "#E04D01",
                                  fontSize: "50px",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingLeft: "20px",
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={9}>
                            <div style={{ paddingTop: "20px" }}>
                              <Stack direction="column" gap={0}>
                                <Typography
                                  sx={{ fontSize: "10px", color: "#E04D01" }}
                                >
                                  TRANSPORTATION
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Transportation Services
                                </Typography>
                              </Stack>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="100"
            value={value}
            index={1}
          >
            <div className={isMobile ? "mobileWhyMission" : "WhyHeadMission"}>
              <Grid container spacing={1}>
                {/* <Grid item xs={3}></Grid> */}
                <Grid item md={6} xs={12}>
                  <img
                    src={vision}
                    className={isMobile ? "mobileImgmv" : "Img"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Stack direction="column" gap={1}>
                    <Typography className={isMobile ? "mobiletxt4" : "txt4"}>
                      Leading Provider of Reading & Transportation Services!
                    </Typography>
                    <Typography
                      className={isMobile ? "mobiletxt2" : "txt2"}
                      // sx={{
                      //   fontSize: "15px",
                      //   fontWeight: "regular",
                      //   textAlign: "justify",
                      //   justifyContent: "justify",
                      // }}
                    >
                      Our vision at MMSC is to become the leading provider of
                      water and electric reading services in our region. We aim
                      to achieve this by consistently exceeding our customers'
                      expectations, expanding our service offerings, and
                      leveraging innovative solutions to solve complex
                      challenges.
                    </Typography>
                    <div style={{ paddingTop: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item md={6} xs={6}>
                          {param.indexTabsData1.map((tabs) => {
                            return (
                              <Stack direction="row" gap={1}>
                                <Typography>{tabs.icon}</Typography>
                                <Typography>{tabs.text}</Typography>
                              </Stack>
                            );
                          })}
                        </Grid>
                        <Grid item md={6} xs={6}>
                          {param.indexTabsData2.map((tabs2) => {
                            return (
                              <Stack direction="row" gap={1}>
                                <Typography>{tabs2.icon}</Typography>
                                <Typography>{tabs2.text}</Typography>
                              </Stack>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </div>
                    <Divider
                      sx={{
                        height: "2px",
                        backgroundColor: "#EEEEEE",
                      }}
                    />
                    <Grid container spacing={0}>
                      <Grid item md={6} xs={12}>
                        <Grid container spacing={1}>
                          <Grid item md={3}>
                            <div
                              style={{
                                paddingTop: "20px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <BoltIcon
                                sx={{
                                  color: "#E04D01",
                                  fontSize: "50px",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  paddingLeft: "20px",
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={9}>
                            <div style={{ paddingTop: "20px" }}>
                              <Stack direction="column" gap={0}>
                                <Typography
                                  sx={{ fontSize: "10px", color: "#E04D01" }}
                                >
                                  READING
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Reading Services
                                </Typography>
                              </Stack>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Grid container spacing={1}>
                          <Grid item md={3}>
                            <div
                              style={{
                                paddingTop: "20px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <DirectionsBusFilledIcon
                                sx={{
                                  color: "#E04D01",
                                  fontSize: "50px",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingLeft: "20px",
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md={9}>
                            <div style={{ paddingTop: "20px" }}>
                              <Stack direction="column" gap={0}>
                                <Typography
                                  sx={{ fontSize: "10px", color: "#E04D01" }}
                                >
                                  TRANSPORTATION
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Transportation Services
                                </Typography>
                              </Stack>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
              <div style={{ padding: "30px 100px" }}>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <img
                      src={values}
                      style={{
                        borderRadius: "10px",
                        width: "90%",
                        height: "380px",
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        sx={{
                          fontSize: "30px",
                          fontWeight: "700",
                          fontFamily: "Exo",
                        }}
                      >
                        We Always Provide Best Quality Building Service.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "regular",
                          textAlign: "justify",
                          justifyContent: "justify",
                        }}
                      >
                        Dramatically foster compelling result before vertical
                        platforms. Globally pursue client focused potentiality
                        without global alignment. Dramatical maximize covalent
                        data with world-class schemas.
                      </Typography>
                      <div style={{ paddingTop: "10px" }}>
                        <Grid container spacing={0}>
                          <Grid item md={6}>
                            {param.indexTabsData1.map((tabs) => {
                              return (
                                <Stack direction="row" gap={1}>
                                  <Typography>{tabs.icon}</Typography>
                                  <Typography>{tabs.text}</Typography>
                                </Stack>
                              );
                            })}
                          </Grid>
                          <Grid item md={6}>
                            {param.indexTabsData2.map((tabs2) => {
                              return (
                                <Stack direction="row" gap={1}>
                                  <Typography>{tabs2.icon}</Typography>
                                  <Typography>{tabs2.text}</Typography>
                                </Stack>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </div>
                      <Divider
                        sx={{ heigght: "5px", backgroundColor: "#EEEEEE" }}
                      />
                      <Grid container spacing={0}>
                        <Grid item md={6}>
                          <Grid container spacing={1}>
                            <Grid item md={3}>
                              <div
                                style={{
                                  paddingTop: "20px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <HandymanOutlinedIcon
                                  sx={{
                                    color: "#E04D01",
                                    fontSize: "50px",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    paddingLeft: "20px",
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={9}>
                              <div style={{ paddingTop: "20px" }}>
                                <Stack direction="column" gap={0}>
                                  <Typography
                                    sx={{ fontSize: "10px", color: "#E04D01" }}
                                  >
                                    CONSTRUCTION
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Construction Services
                                  </Typography>
                                </Stack>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          <Grid container spacing={1}>
                            <Grid item md={3}>
                              <div
                                style={{
                                  paddingTop: "20px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <SquareFootOutlinedIcon
                                  sx={{
                                    color: "#E04D01",
                                    fontSize: "50px",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingLeft: "20px",
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={9}>
                              <div style={{ paddingTop: "20px" }}>
                                <Stack direction="column" gap={0}>
                                  <Typography
                                    sx={{ fontSize: "10px", color: "#E04D01" }}
                                  >
                                    CONSTRUCTION
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Architecture Design
                                  </Typography>
                                </Stack>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div style={{ padding: "30px 100px" }}>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <img
                      src={award}
                      style={{
                        borderRadius: "10px",
                        width: "90%",
                        height: "380px",
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Stack direction="column" gap={1}>
                      <Typography
                        sx={{
                          fontSize: "30px",
                          fontWeight: "700",
                          fontFamily: "Exo",
                        }}
                      >
                        We Got A Lots Of Award During The Century
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "regular",
                          textAlign: "justify",
                          justifyContent: "justify",
                        }}
                      >
                        Dramatically foster compelling result before vertical
                        platforms. Globally pursue client focused potentiality
                        without global alignment. Dramatical maximize covalent
                        data with world-class schemas.
                      </Typography>
                      <div style={{ paddingTop: "10px" }}>
                        <Grid container spacing={0}>
                          <Grid item md={6}>
                            {param.indexTabsData1.map((tabs) => {
                              return (
                                <Stack direction="row" gap={1}>
                                  <Typography>{tabs.icon}</Typography>
                                  <Typography>{tabs.text}</Typography>
                                </Stack>
                              );
                            })}
                          </Grid>
                          <Grid item md={6}>
                            {param.indexTabsData2.map((tabs2) => {
                              return (
                                <Stack direction="row" gap={1}>
                                  <Typography>{tabs2.icon}</Typography>
                                  <Typography>{tabs2.text}</Typography>
                                </Stack>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </div>
                      <Divider
                        sx={{ heigght: "5px", backgroundColor: "#EEEEEE" }}
                      />
                      <Grid container spacing={0}>
                        <Grid item md={6}>
                          <Grid container spacing={1}>
                            <Grid item md={3}>
                              <div
                                style={{
                                  paddingTop: "20px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <HandymanOutlinedIcon
                                  sx={{
                                    color: "#E04D01",
                                    fontSize: "50px",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    paddingLeft: "20px",
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={9}>
                              <div style={{ paddingTop: "20px" }}>
                                <Stack direction="column" gap={0}>
                                  <Typography
                                    sx={{ fontSize: "10px", color: "#E04D01" }}
                                  >
                                    CONSTRUCTION
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Construction Services
                                  </Typography>
                                </Stack>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          <Grid container spacing={1}>
                            <Grid item md={3}>
                              <div
                                style={{
                                  paddingTop: "20px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <SquareFootOutlinedIcon
                                  sx={{
                                    color: "#E04D01",
                                    fontSize: "50px",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingLeft: "20px",
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md={9}>
                              <div style={{ paddingTop: "20px" }}>
                                <Stack direction="column" gap={0}>
                                  <Typography
                                    sx={{ fontSize: "10px", color: "#E04D01" }}
                                  >
                                    CONSTRUCTION
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Architecture Design
                                  </Typography>
                                </Stack>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </TabPanel> */}
        </Box>
      </div>

      {/* <Achievements /> */}
      <div className="Achievementbg">
        <div className={isMobile ? "mobileAchieve" : "achievements"}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <div>
                      <Stack direction="row" gap={2} marginBottom="20px">
                        <CoPresentSharpIcon
                          sx={{ color: "#e04d01", fontSize: "32px" }}
                        />
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#e04d01",
                            // fontFamily: "Exo",
                          }}
                        >
                          ACHIEVEMENTS
                        </Typography>
                      </Stack>
                      <Typography
                        data-aos="zoom-in-up"
                        data-aos-duration="800"
                        data-aos-delay="100"
                        className={isMobile ? "mobileAchieveTxt" : "achieveTxt"}
                      >
                        Let's Start, We Are On <br /> Building Of Dream
                      </Typography>

                      <Typography
                        data-aos="zoom-in-up"
                        data-aos-duration="800"
                        data-aos-delay="150"
                        style={{
                          opacity: "80%",
                          textAlign: "justify",
                          marginTop: "25px",
                        }}
                      >
                        MMSC Company has made remarkable achievements in the
                        electric and water reading services field by introducing
                        advanced technologies and systems that have improved
                        accuracy and efficiency in meter reading, resulting in
                        cost savings for both customers and the company.
                      </Typography>
                      <div style={{ paddingTop: "20px" }}>
                        <Grid container spacing={4}>
                          {/* {param.indexAchieveData.map((achieve, index) => {
                            const multiplier = index * 100;
                            return (
                              <Achievements
                                delay={multiplier}
                                icon={achieve.icon}
                                title={achieve.title}
                                text={achieve.text}
                              />
                            );
                          })} */}
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div
                      style={{
                        width: "100%",
                        alignItems: "right",
                      }}
                    >
                      <img
                        src={achievement}
                        className={isMobile ? "mobileAchieveImg" : "AchieveImg"}
                        // style={{
                        //   width: "90%",
                        //   // padding: "30px",
                        //   height: "90%",
                        // }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Contact Us */}
      {/* <Grid item md={12}>
        <div id="contact" ref={contactRef} className="contact">
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}></Grid>
              <Grid item md={6} xs={12}>
                <div style={{ backgroundColor: "EEEEEE", padding: "30px" }}>
                  <Stack
                    direction="row"
                    gap={2}
                    marginBottom="20px"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <CoPresentSharpIcon
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        fontSize: "32px",
                      }}
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        // fontFamily: "Exo",
                      }}
                    >
                      CONTACT US
                    </Typography>
                  </Stack>
                  <Typography
                    className={isMobile ? "mobilecontacttxt" : "contacttxt"}
                  >
                    Contact Us
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <CssTextField
                        label="First Name"
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CssTextField
                        label="Last Name"
                        variant="filled"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CssTextField label="Email" variant="filled" fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CssTextField
                        label="Subject"
                        variant="filled"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <CssTextField
                        fullWidth
                        label="How Can We Help You?"
                        multiline
                        rows={4}
                        maxRows={6}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                  <div style={{ paddingTop: "30px" }}>
                    <Grid container>
                      <Grid item md={8} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#ff5e14",
                                "&.Mui-checked": {
                                  color: "#ff5e14",
                                },
                              }}
                            />
                          }
                          label="I Agree to the Terms & Conditions"
                          sx={{ color: "#fff" }}
                        />
                      </Grid>
                      <Grid item md={4} sx={{ textAlign: "right" }}>
                        <Button
                          elevation={0}
                          variant="contained"
                          sx={{
                            paddingLeft: "30px",
                            paddingRight: "30px",
                            backgroundColor: "#ff5e14",
                            "&:hover": {
                              backgroundColor: "#EE9D1C",
                            },
                          }}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid> */}
      {/* </Grid> */}
    </>
  );
};

export default IndexPage;
