import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

//import css
import "../../css/indexpage/IndexPageStyles.css";
const OtherServices = (props) => {
  const { ...param } = props;
  return (
    <>
      <Grid item md={3} xs={12} sx={{ margin: "0 auto" }}>
        <div
          //   onClick={() => param.selectedService(para.route)}
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay={param.delay}
          style={{
            // padding: "10px",
            margin: "0 auto",
          }}
        >
          <div style={{}}>
            <Card
              // className="servicesCard"
              style={{
                height: "auto",
                backgroundColor: "transparent",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {/* <CardMedia>
                <div style={{ width: "50px", height: "50px" }}>
                  <img src={param.image} />
                </div>
              </CardMedia> */}
              {/* <CardMedia img={param.image} /> */}
              <Grid container spacing={0}>
                <Grid item md={12} xs={12}>
                  <Stack>
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        // gap: 2,
                      }}
                    >
                      {/* <Typography className="icon">{param.icon}</Typography> */}
                      <img
                        src={param.image}
                        className="mobileMMSCServImg"
                        style={{
                          boxShadow: "-12px 13px 5px -3px rgba(0,0,0,0.08)",
                        }}
                      />
                      {/* <img src={param.image} style={{ width: "200px" }} /> */}

                      <Typography className="text">{param.text}</Typography>
                    </CardContent>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default OtherServices;
