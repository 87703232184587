import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//import assets
import mmsc from "../assets/mmsc_logo.png";
//import icons
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";

//Component Import
import FooterHook from "../hooks/FooterHook";
//Media Query
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

//import css
import "../css/indexpage/IndexPageStyles.css";
const Footer = () => {
  const { ...param } = FooterHook();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div style={{ backgroundColor: "#222831", width: "100%" }}>
        <Grid container spacing={1}>
          <Grid item md={3} xs={12}>
            <div
              style={{
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                paddingTop: "50px",
              }}
            >
              <img
                src={mmsc}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.replace("/");
                }}
              />
            </div>
          </Grid>
          <Grid item md={9} xs={12}>
            <div
              className={isMobile ? "mobileFooterpg" : "Footerpg"}
              style={{ padding: "50px 20px 0px 20px" }}
            >
              <Grid container spacing={1}>
                <Grid item md={5}>
                  <Typography sx={{ color: "#686D76" }}>
                    {/* READY FOR A SUBSCRIPTION? */}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "30px",
                      fontWeight: "800",
                      color: "#E8E8E8",
                      // fontFamily: "Exo",
                    }}
                  >
                    {/* Subscribe our Latest News */}
                  </Typography>
                </Grid>
                <Grid item md={1} xs={1}></Grid>
                {/* <Grid item md={4} xs={6}>
                  <TextField
                    id="outlined-textarea"
                    placeholder="Email"
                    multiline
                    fullWidth="100%"
                    height="20px"
                    sx={{ backgroundColor: "#fff" }}
                  />
                </Grid>
                <Grid item md={2} xs={4}>
                  <Button
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "10px",
                      padding: "20px",
                      backgroundColor: "#EE9D1C",
                      "&:hover": {
                        color: "black",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    Emial Us
                  </Button>
                </Grid> */}
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div style={{ paddingTop: "20px" }}>
          <Divider
            sx={{
              height: "0px",
              backgroundColor: "#fff",
              width: "100%",
            }}
          ></Divider>
        </div>
        <Grid contaner spacing={1}>
          <Grid item md={12} xs={12}>
            <div className={isMobile ? "mobilefpadding1" : "fpadding1"}>
              <Grid container spacing={3}>
                {/* about company */}
                <Grid item md={3} xs={12}>
                  <Stack direction="column" gap={2}>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "600",
                        // fontFamily: "Exo",
                      }}
                    >
                      ABOUT COMPANY
                    </Typography>
                    <Divider
                      sx={{
                        height: "5px",
                        backgroundColor: "#EE9D1C",
                        "&:hover": { backgroundColor: "#fff" },
                      }}
                    ></Divider>
                    <Typography
                      sx={{
                        paddingTop: "10px",
                        color: "white",
                        fontSize: "15px",
                        textAlign: "justify",
                      }}
                    >
                      MMSC is a leading provider of services in the water and
                      electric reading industry, with a focus on accuracy and
                      efficiency in meter reading. As a company, MMSC continues
                      to strive towards providing the highest quality services
                      and maintaining a strong commitment to their customers,
                      employees, and the environment.
                    </Typography>
                    {/* <Stack direction="row" gap={2}>
                      <FacebookIcon
                        sx={{
                          color: "white",
                          fontSize: "35px",
                          "&:hover": { color: "#f94a29" },
                          cursor: "pointer",
                        }}
                      />
                      <TwitterIcon
                        sx={{
                          color: "white",
                          fontSize: "35px",
                          "&:hover": { color: "#f94a29" },
                          cursor: "pointer",
                        }}
                      />
                      <LinkedInIcon
                        sx={{
                          color: "white",
                          fontSize: "35px",
                          "&:hover": { color: "#f94a29" },
                          cursor: "pointer",
                        }}
                      />
                      <WhatsAppIcon
                        sx={{
                          color: "white",
                          fontSize: "35px",
                          "&:hover": { color: "#f94a29" },
                          cursor: "pointer",
                        }}
                      />
                    </Stack> */}
                  </Stack>
                </Grid>
                {/* Quick links */}
                <Grid item md={3} xs={12}>
                  <Stack direction="column" gap={2}>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "600",
                        // fontFamily: "Exo",
                      }}
                    >
                      QUICK LINKS
                    </Typography>
                    <Divider
                      sx={{
                        height: "5px",
                        backgroundColor: "#EE9D1C",
                        "&:hover": { backgroundColor: "#fff" },
                      }}
                    ></Divider>
                    <Grid container spacing={0}>
                      <Grid item md={12} xs={12}>
                        {param.indexLinkData.map((val2) => {
                          return (
                            <List
                              sx={{
                                color: "#fff",
                              }}
                            >
                              <ListItem
                                disableGutters
                                sx={{
                                  paddingBottom: "0px",
                                  "&:hover": { color: "#ff5e14" },
                                  paddingLeft: "20px",
                                }}
                              >
                                <ListItemText alignItems="left">
                                  <Stack
                                    direction="row"
                                    alignItems="left"
                                    justifyContent="left"
                                  >
                                    {val2.icon}
                                    <Typography
                                      onClick={(e) => {
                                        e.preventDefault();
                                        window.location.replace(val2.path);
                                      }}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      {val2.text}
                                    </Typography>
                                  </Stack>
                                </ListItemText>
                              </ListItem>
                            </List>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
                {/* Contact Now */}
                <Grid item md={3} xs={12}>
                  <Stack direction="column" gap={2}>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "600",
                        // fontFamily: "Exo",
                      }}
                    >
                      CONTACT NOW
                    </Typography>
                    <Divider
                      sx={{
                        height: "5px",
                        backgroundColor: "#EE9D1C",
                        "&:hover": { backgroundColor: "#fff" },
                      }}
                    ></Divider>
                    <Stack direction="row" gap={1}>
                      <AddLocationOutlinedIcon
                        sx={{
                          backgroundColor: "#EE9D1C",
                          borderRadius: "50%",
                          color: "white",
                          fontsize: "20px",
                          padding: "2px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "15px",
                          "&:hover": { color: "#f94a29" },
                        }}
                      >
                        86 E Abada St. Loyola Heights QC
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <PhoneIphoneOutlinedIcon
                        sx={{
                          backgroundColor: "#EE9D1C",
                          borderRadius: "50%",
                          color: "white",
                          fontsize: "20px",
                          padding: "2px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "15px",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          "&:hover": { color: "#f94a29" },
                        }}
                      >
                        (+63) 917-119-5111
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <MailOutlinedIcon
                        sx={{
                          backgroundColor: "#EE9D1C",
                          borderRadius: "50%",
                          color: "white",
                          fontsize: "20px",
                          padding: "2px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "15px",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          "&:hover": { color: "#f94a29" },
                        }}
                      >
                        businessdev@mmscph.com
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                {/* Gallery Post */}
                <Grid item md={3} xs={12}>
                  <Stack direction="column" gap={2}>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "600",
                        // fontFamily: "Exo",
                      }}
                    >
                      {/* GALLERY POSTS */}
                    </Typography>
                    {/* <Divider
                      sx={{
                        height: "5px",
                        backgroundColor: "#EE9D1C",
                        "&:hover": { backgroundColor: "#fff" },
                      }}
                    ></Divider> */}
                    <Grid container spacing={1}>
                      {/* {param.indexGalleryData.map((val) => {
                        return (
                          <Grid item md={4} xs={4}>
                            <div>
                              <img
                                src={val.image}
                                style={{
                                  width: "90%",

                                  borderRadius: "10px",
                                }}
                              />
                            </div>
                          </Grid>
                        );
                      })} */}
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div style={{ paddingTop: "0px" }}>
          <Divider
            sx={{
              height: "0px",
              backgroundColor: "#fff",
              width: "100%",
            }}
          ></Divider>
        </div>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <div style={{ padding: "30px 100px" }}>
              <Typography
                sx={{ color: "white", fontSize: "16px", textAlign: "center" }}
              >
                {/* Copyright © 2023 GzoneTech. All Rights Reserved. */}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Footer;
