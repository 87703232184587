import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";

//component react
import IndexPage from "./pages/indexPage/IndexPage";
import NavBar from "./components/navbar/NavBar";
import Footer from "./components/Footer";
//page imports

//css imports
import { createTheme, palette } from "@mui/system";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f94a29",
    },
    tabs: {
      backgroundColor: "red",
    },
    inkBar: {
      backgroundColor: "yellow",
    },
    divider: "#013553",
  },
});
function App() {
  return (
    <div>
      <HashRouter>
        <NavBar />
        <IndexPage />
        <div></div>
        {/* <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/reconnection" element={<Reconnection />} />
          <Route path="/disconnection" element={<Disconnection />} />
          <Route path="/spotbilling" element={<SpotBilling />} />
          <Route path="/meterreading" element={<MeterReading />} />
          <Route path="/courierservices" element={<CourierServices />} />
          <Route path="/billsdelivery" element={<BillsDelivery />} />
          <Route path="/fieldaudit" element={<FieldAudit />} />
          <Route path="/transportservices" element={<TransportService />} />

          <Route path="/buildingtansaction" element={<BuildingTransaction />} />
          <Route path="/projectplanning" element={<ProjectPlanning />} />
          <Route path="/interiordesign" element={<InteriorDesign />} />
          <Route path="/qualitymaterials" element={<QualityMaterials />} />
          <Route path="/architecturedesign" element={<ArchitectureDesign />} />
          <Route
            path="/createprofessionals"
            element={<CreateProfessionals />}
          />
          <Route
            path="/createprofessionals"
            element={<CreateProfessionals />}
          />
        </Routes> */}
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
