import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
//import icons
import PhoneEnabledRoundedIcon from "@mui/icons-material/PhoneEnabledRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";

// imports used for navigation
import { useDispatch } from "react-redux";
import { OnScrollLInk } from "../../actions/IndexAction";

const NavbarHook = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    bool: true,
    data: [
      {
        page: "HOME",
        path: "/home",
      },
      {
        page: "ABOUT US",
        path: "/#about",
      },
      {
        page: "SERVICES",
        path: "/#services",
      },

      {
        page: "CONTACT US",
        path: "/#contact",
      },
    ],
    // navTopItems: [
    //   { icon: <PhoneEnabledRoundedIcon />, text: "(+63) 917 1195 111" },
    //   { icon: <EmailRoundedIcon />, text: "businessdev@mmscph.com" },
    //   {
    //     icon: <AccessTimeFilledRoundedIcon />,
    //     text: "8:00AM - 5:00PM",
    //   },
    // ],
  });

  // const onClickNavigate = (path) => {
  //   navigate(path);
  // };
  const onClickMenu = (route) => {
    console.log(route);
    dispatch(OnScrollLInk(route));
  };
  return {
    ...state,
    onClickMenu,
  };
  // return {
  //   ...state,
  //   onClickNavigate,
  // };
};

export default NavbarHook;
