import { ACTION_INDEX } from "../constant/Constant";
const initialState = {
  selectedLink: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_INDEX:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
