import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

//Media Query
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

//css
import "../../css/indexpage/IndexPageStyles.css";

const Services = (props) => {
  const { ...param } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid item md={3} xs={6}>
        <div
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay={param.delay}
          className="serviceCard"
          style={{
            padding: "10px",
          }}
        >
          <div>
            <Card className={isMobile ? "mobileCardServ" : "ServCard"}>
              <CardContent>
                <Typography
                  className={isMobile ? "mobileCardServT" : "ServCardT"}
                >
                  {param.icon}
                </Typography>

                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "800",
                  }}
                >
                  {param.title}
                </Typography>
                <Typography
                  className={isMobile ? "mobileServDes" : "ServDes"}
                  // sx={{
                  //   opacity: "80%",
                  //   textAlign: "justify",
                  //   fontSize: "15px",
                  // }}
                >
                  {param.text}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default Services;
