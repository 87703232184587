import React, { useState } from "react";

//Navbarver2
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
//import assets
import mmsc_logo from "../../assets/mmsc_logo.png";
import logo from "../../assets/logo.png";
//component import
// hook import
import NavbarHook from "../../hooks/navbar/NavbarHook";
//import css
import "../../css/navbar/NavbarStyles.css";
//Media Query
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

//drawer size
const drawerWidth = 240;

const NavBar = (props) => {
  // hooks
  const { ...param } = NavbarHook();
  // media query
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  // const isTablet = useMediaQuery(theme.breakpoints.down("768"));
  //mobile
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography> */}
      <img
        // onClick={(e) => {
        //   e.preventDefault();
        //   window.location.replace("/");
        // }}
        src={mmsc_logo}
        className="img2"
      />
      <Divider />
      <List>
        {/* {navItems.map((item) => ( */}
        {param.data.map((item, index) => {
          return (
            <ListItem
              onClick={() => param.onClickMenu(item.page)}
              key={index}
              disablePadding
            >
              <ListItemButton sx={{ textAlign: "center", fontWeight: "900" }}>
                <ListItemText primary={item.page} sx={{ fontWeight: "900" }} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div
      // className={colorChange ? "navbar colorChange" : "navbar"}
      // r
      style={{
        position: "fixed",
        zIndex: "2",
        top: 0,
        width: "100%",
      }}
    >
      {/* black part of nav */}
      {/* <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <div className={isMobile ? "mobileNav" : "Nav"}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <div
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid container spacing={1}>
                    {param.navTopItems.map((val1, index) => {
                      return (
                        <Grid
                          item
                          md={4}
                          xs={0}
                          sx={{
                            color: "white",
                            textAlign: "center",
                            display: "flex",
                          }}
                        >
                          <Stack
                            direction="row"
                            gap={0}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                fontSize: "12px ",
                              }}
                            >
                              {val1.icon}
                            </Typography>

                            <Typography
                              variant="caption"
                              className={isMobile ? "mobileTxt" : "txt"}
                            >
                              {val1.text}
                            </Typography>
                          </Stack>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Grid>
              <Grid item md={4}></Grid>
            </Grid>
          </div>
        </Grid>
      </Grid> */}

      {/* main nav */}
      <Box className="NavBar" sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar component="nav" sx={{ backgroundColor: "#e04d01" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Grid container spacing={1}>
              <Grid item md={4} xs={10}>
                <img
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.replace("/#");
                  }}
                  src={logo}
                  className={isMobile ? "mobileImg" : "img"}
                />
              </Grid>
              <Grid item md={8}>
                <Box
                  sx={{
                    textAlign: "right",
                    paddingTop: "20px",
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  {/* <img src={logo} className="img" /> */}
                  {/* {navItems.map((item) => ( */}
                  {param.data.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        onClick={() => param.onClickMenu(item.page)}
                        sx={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "900",
                          "&:hover": { color: "#ee9d1c" },
                        }}
                      >
                        {item.page}
                      </Button>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
};

export default NavBar;
