import { ACTION_INDEX } from "../constant/Constant";

export const OnScrollLInk = (route) => async (dispatch) => {
  console.log(route);
  dispatch({
    type: ACTION_INDEX,
    payload: {
      selectedLink: route,
    },
  });
};
