import React, { useState } from "react";

//icon immports
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import EdgesensorHighOutlinedIcon from "@mui/icons-material/EdgesensorHighOutlined";
import ElectricMeterOutlinedIcon from "@mui/icons-material/ElectricMeterOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import ConstructionIcon from "@mui/icons-material/Construction";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
//assets import
import Engr from "../../assets/engr.jpg";
import Proj from "../../assets/project1.jpg";
import engr2 from "../../assets/engr2.jpg";
import engr3 from "../../assets/engr3.jpg";
import sitemanager from "../../assets/sitemanager.jpg";
//imports

// client image imports
import ileco from "../../assets/ileco_logo.png";
import baliwag from "../../assets/mmsc_client.png";
import metrolipa from "../../assets/metrolipa.jfif.png";
import meralco from "../../assets/meralco.png";
import manilawater from "../../assets/manila_water_logo.png";
import maynilad from "../../assets/maynilad.png";
import prime_water from "../../assets/prime_water.png";
import more_power from "../../assets/more_power.png";

const IndexaPageHook = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    indexCardData: [
      {
        icon: <EngineeringOutlinedIcon sx={{ fontSize: 40 }} />,
        title: "Reconnection",
        text: "We train and provide field men to accomodate customer in restoring their connection",
        // route1: "/reconnection",
      },
      {
        icon: <EdgesensorHighOutlinedIcon sx={{ fontSize: 40 }} />,

        title: "Disconnection",
        text: "We train and provide field men to accomodate the client's meter disconnection request",
        // route1: "/disconnection",
      },
      {
        icon: <ElectricMeterOutlinedIcon sx={{ fontSize: 40 }} />,

        title: "Spot Billing",
        text: "Create receipts on the spot while roving in the assigned location",
        // route1: "/spotbilling",
      },
      {
        icon: <MapOutlinedIcon sx={{ fontSize: 40 }} />,

        title: "Meter Reading",
        text: "Accurate and complete reading of water and electric meters in volumes",
        // route1: "/meterreading",
      },
      {
        icon: <LocalShippingIcon sx={{ fontSize: 40 }} />,
        title: "Courier Services",
        text: "Integrated services for the collection, transmission and delivery of any package that can be collected.",
        // route1: "/courierservices",
      },
      {
        icon: <ReceiptLongIcon sx={{ fontSize: 40 }} />,
        title: "Bills Delivery",
        text: "Integrated services for the collecion transmission and delivery of electric or water bills.",
        // route1: "/billsdelivery",
      },
      {
        icon: <VerifiedUserIcon sx={{ fontSize: 40 }} />,
        title: "Field Audit / Survey",
        text: "Face-to-face examination conducted at your place of business",
        // route1: "/fieldaudit",
      },
      {
        icon: <LocalTaxiIcon sx={{ fontSize: 40 }} />,
        title: "Transport Service",
        text: "Service for moving people and goods, such as intercity bus services",
        // route1: "/transportservices",
      },
    ],
    indexSolutionData: [
      {
        // icon: <EngineeringOutlinedIcon sx={{ fontSize: 60, color: "#000" }} />,
        image: ileco,
        text: "Ileco II",
      },
      {
        // icon: (
        //   <EdgesensorHighOutlinedIcon sx={{ fontSize: 60, color: "#000" }} />
        // ),
        image: baliwag,

        text: "Baliwag Water",
      },
      {
        // icon: (
        //   <ElectricMeterOutlinedIcon sx={{ fontSize: 60, color: "#000" }} />
        // ),
        image: meralco,

        text: "Meralco",
      },
      {
        // icon: <MapOutlinedIcon sx={{ fontSize: 60, color: "#000" }} />,
        image: metrolipa,

        text: "Metro Lipa",
      },
      {
        // icon: <MapOutlinedIcon sx={{ fontSize: 60, color: "#000" }} />,
        image: manilawater,

        text: "Manila Water",
      },
      {
        // icon: <MapOutlinedIcon sx={{ fontSize: 60, color: "#000" }} />,
        image: maynilad,

        text: "Maynilad",
      },
      {
        // icon: <MapOutlinedIcon sx={{ fontSize: 60, color: "#000" }} />,
        image: prime_water,

        text: "Prime Water",
      },
      {
        // icon: <MapOutlinedIcon sx={{ fontSize: 60, color: "#000" }} />,
        image: more_power,

        text: "More Power",
      },
    ],
    indexTeamData: [
      {
        image: Engr,
        title: "Michael Richard",
        text: "Architecture",
      },
      {
        image: engr2,
        title: "Famhida Ruko",
        text: "Engineer",
      },
      {
        image: engr3,
        title: "Jackline Farah",
        text: "Engineer",
      },
      {
        image: sitemanager,
        title: "Alex Anfantino",
        text: "Site Manager",
      },
      {
        // icon: <EngineeringOutlinedIcon sx={{ fontSize: 60, color: "#000" }} />,
        image: null,
        text: "",
      },
    ],
    indexProjData: [
      {
        image: Proj,
        title: "CONSTRUCTION",
        text: "Contemporary Villa",
      },
      {
        image: Proj,
        title: "CONSTRUCTION",
        text: "Rowson Construction",
      },
      {
        image: Proj,
        title: "CONSTRUCTION",
        text: "Interior Decoration",
      },
      {
        image: Proj,
        title: "CONSTRUCTION",
        text: "Construction Planning",
      },
    ],
    indexActionData: [
      { icon: <FacebookIcon />, name: "Facebook" },
      { icon: <TwitterIcon />, name: "Twitter" },
      { icon: <InstagramIcon />, name: "Instagram" },
      { icon: <LinkedInIcon />, name: "LinkedIn" },
    ],
    indexButtonData: [
      { name: "Mission" },
      { name: "Vision" },
      { name: "Value" },
      { name: "Award Winning" },
    ],
    indexAchieveData: [
      {
        icon: <HandshakeIcon sx={{ fontSize: "30px" }} />,
        title: "25",
        text: "Complete Projects",
      },
      {
        icon: <ThumbUpOffAltIcon sx={{ fontSize: "30px" }} />,
        title: "16",
        text: "Active on Clients",
      },
      {
        icon: <Diversity3Icon sx={{ fontSize: "30px" }} />,
        title: "1.2",
        text: "Experience Team",
      },
      {
        icon: <EmojiEventsIcon sx={{ fontSize: "30px" }} />,
        title: "1.4",
        text: "Winning Awards",
      },
    ],
    // indexTabData: [
    //   {
    //     indexTabDeetsData: [
    //       {
    //         image: mission,
    //         head: "Leading Way In Building & Civil Constructions!",
    //         text: "Dramatically foster compelling result before vertical platforms. Globally pursue client focused potentiality without global alignment. Dramatical maximize covalent data with world-class schemas.",
    //         icon: <VerifiedRoundedIcon />,
    //         text2: "Commercial Services",
    //         text3: "Industrial Services",
    //         text3: "Residential Services",
    //         text3: "Construction Services",
    //       },
    //     ],
    //   },
    //   {
    //     indexTabDeets2Data: [
    //       {
    //         image: vision,
    //         head: "Making The Way Of Building & Constructions!",
    //         text: "Dramatically foster compelling result before vertical platforms. Globally pursue client focused potentiality without global alignment. Dramatical maximize covalent data with world-class schemas.",
    //         icon: <VerifiedRoundedIcon />,
    //         text2: "Commercial Services",
    //         text3: "Industrial Services",
    //         text3: "Residential Services",
    //         text3: "Construction Services",
    //       },
    //     ],
    //   },
    //   {
    //     indexTabDeets3Data: [
    //       {
    //         image: value,
    //         head: "We Always Provide Best Quality Building Service.",
    //         text: "Dramatically foster compelling result before vertical platforms. Globally pursue client focused potentiality without global alignment. Dramatical maximize covalent data with world-class schemas.",
    //         icon: <VerifiedRoundedIcon />,
    //         text2: "Commercial Services",
    //         text3: "Industrial Services",
    //         text3: "Residential Services",
    //         text3: "Construction Services",
    //       },
    //     ],
    //   },
    //   {
    //     indexTabDeets4Data: [
    //       {
    //         image: award,
    //         head: "We Got A Lots Of Award During The Century",
    //         text: "Dramatically foster compelling result before vertical platforms. Globally pursue client focused potentiality without global alignment. Dramatical maximize covalent data with world-class schemas.",
    //         icon: <VerifiedRoundedIcon />,
    //         text2: "Commercial Services",
    //         text3: "Industrial Services",
    //         text3: "Residential Services",
    //         text3: "Construction Services",
    //       },
    //     ],
    //   },
    // ],
    indexTabsData1: [
      {
        icon: <VerifiedRoundedIcon sx={{ color: "#E04D01" }} />,
        text: "Electric Services",
      },
      {
        icon: <VerifiedRoundedIcon sx={{ color: "#E04D01" }} />,
        text: "Courier Services",
      },
    ],
    indexTabsData2: [
      {
        icon: <VerifiedRoundedIcon sx={{ color: "#E04D01" }} />,
        text: "Water Services",
      },
      {
        icon: <VerifiedRoundedIcon sx={{ color: "#E04D01" }} />,
        text: "Transportation Services",
      },
    ],

    socialMedia: [
      {
        icon: null,
      },
      {
        icon: <LinkedInIcon />,
      },
    ],
  });

  const selectedService = (route) => {
    navigate(route);
  };
  const selectedServ = (route1) => {
    navigate(route1);
  };

  return {
    ...state,
    selectedService,
    selectedServ,
  };
};
export default IndexaPageHook;
