import React from "react";
import { useState } from "react";
//import assets
import gal1 from "../assets/gal-1.jpg";
import gal2 from "../assets/gal-2.jpg";
import gal3 from "../assets/gal-3.jpg";
import gal4 from "../assets/gal-4.jpg";
import gal5 from "../assets/gal-5.jpg";
import gal6 from "../assets/gal-6.jpg";
//import icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const FooterHook = () => {
  const [state2, setState] = useState({
    indexGalleryData: [
      { image: gal1 },
      { image: gal2 },
      { image: gal3 },
      { image: gal4 },
      { image: gal5 },
      { image: gal6 },
    ],
    indexLinkData: [
      {
        icon: <ArrowForwardIcon sx={{ color: "white" }} />,
        text: "About Us",
        path: "/#about",
      },
      {
        icon: <ArrowForwardIcon sx={{ color: "white" }} />,
        text: "Services",
        path: "/#services",
      },
      {
        icon: <ArrowForwardIcon sx={{ color: "white" }} />,
        text: "Service Details",
        path: "/#services",
      },
      {
        icon: <ArrowForwardIcon sx={{ color: "white" }} />,
        text: "Contact Us",
        path: "/#contact",
      },
    ],
  });
  return {
    ...state2,
  };
};

export default FooterHook;
